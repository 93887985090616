var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Opname")])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _c(
                "div",
                { staticClass: "elementPanel elementPanel--spaced" },
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e(),
                  !_vm.isLoading && _vm.$store.state.isServiceOrganization
                    ? [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      wrap: "",
                                      "justify-space-between": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "info-icon",
                                        attrs: { shrink: "" }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openReadMoreDialog(
                                                                  "rapport_aantallen_opname"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info_outline")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3978787673
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("klik voor meer info")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.totals, function(total, index) {
                              return _c(
                                "v-flex",
                                {
                                  key: index,
                                  attrs: {
                                    xs12: "",
                                    sm3: "",
                                    "text-center": ""
                                  }
                                },
                                [
                                  _c("StatisticsNumberWidget", {
                                    attrs: {
                                      item: {
                                        class: [
                                          _vm.getKpiClass(
                                            total.value,
                                            93,
                                            90,
                                            true
                                          )
                                        ],
                                        icon: "zoom_in",
                                        amount: total.value,
                                        description: total.key
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        ),
                        _c(
                          "v-layout",
                          { staticClass: "item__list", attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__header",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      wrap: "",
                                      "align-center": "",
                                      "justify-space-between": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", { attrs: { xs3: "" } }, [
                                      _c("span", [_vm._v("Expertise Bureau")])
                                    ]),
                                    _c("v-flex", { attrs: { xs9: "" } }, [
                                      _c("span", [_vm._v("Vrijgegeven t/m")])
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.organizations, function(organization) {
                              return _c(
                                "v-flex",
                                {
                                  key: organization.id,
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        wrap: "",
                                        "align-center": "",
                                        "justify-space-between": ""
                                      }
                                    },
                                    [
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v(
                                          " " + _vm._s(organization.name) + " "
                                        )
                                      ]),
                                      _c("v-flex", { attrs: { xs9: "" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              organization.planningEndDate
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm.$store.state.isServiceOrganization
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
                        _c("h2", { staticClass: "elementSubTitle" }, [
                          _vm._v("Gemaakte afspraken")
                        ])
                      ]),
                      _c(
                        "v-flex",
                        {
                          staticClass: "relative",
                          attrs: { xs6: "", "text-right": "" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "elementTitleButton absolute" },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "dialog",
                                  attrs: {
                                    "close-on-content-click": false,
                                    lazy: "",
                                    "nudge-right": "100",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "input input-date",
                                    attrs: {
                                      slot: "activator",
                                      placeholder: "DD-MM-YYYY",
                                      label: "Datum",
                                      "error-messages": _vm.date.errorMessage
                                    },
                                    slot: "activator",
                                    model: {
                                      value: _vm.date.formattedDate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.date, "formattedDate", $$v)
                                      },
                                      expression: "date.formattedDate"
                                    }
                                  }),
                                  _c("v-date-picker", {
                                    ref: "picker",
                                    attrs: {
                                      "first-day-of-week": "1",
                                      locale: "nl-nl",
                                      min: "1910-01-01",
                                      color: "#837f16"
                                    },
                                    on: { change: _vm.fetchStatisticsDetail },
                                    model: {
                                      value: _vm.date.selectedDate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.date, "selectedDate", $$v)
                                      },
                                      expression: "date.selectedDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$store.state.isServiceOrganization
                ? _c(
                    "div",
                    { staticClass: "elementPanel elementPanel--spaced" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "justify-space-between": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "info-icon",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openReadMoreDialog(
                                                                "rapport_table_opname"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [_vm._v("info_outline")]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            692155895
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("klik voor meer info")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { x12: "" } },
                            [
                              !_vm.isLoading
                                ? _c("WeekStatisticsView", {
                                    attrs: {
                                      items: _vm.planningStatistics,
                                      columns: _vm.columns
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }